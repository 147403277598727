import React, { useContext } from "react";
import accounting from "accounting";
import { checkoutContext } from "../../context/checkoutContextProvider";
import { cartSum } from "./cartSum";
import style from "./cart.module.sass";
import { Link } from "gatsby";

const CartBox = () => {
    const { cart } = useContext(checkoutContext);

    const cartTotal = cartSum(cart, true);
    const emptyCart = Object.entries(cart).length === 0;

    return (
        <Link className={style.cartBox} to={"/e-shop/cart"}>
            <svg
                width="26"
                height="20"
                viewBox="0 0 26 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M8.5 10L8.5 16M13.5 10V16M18.5 10V16"
                    stroke="#AD9752"
                    strokeLinecap="round"
                />
                <path
                    d="M5 7L7 4L9 1"
                    stroke="#AD9752"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M21 7L19 4L17 1"
                    stroke="#AD9752"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M1 7H25"
                    stroke="#AD9752"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M3 7V16C3 17.6569 4.34315 19 6 19H20C21.6569 19 23 17.6569 23 16V7"
                    stroke="#AD9752"
                />
            </svg>
            <span>NÁKUPNÍ KOŠÍK</span>
            {!emptyCart && (
                <div className={style.cartBoxPrice}>
                    {accounting.formatNumber(cartTotal.totalPrice, 0, " ", ",")}
                    <span>Kč</span>
                </div>
            )}
        </Link>
    );
};

export default CartBox;
