import React from "react";

import footerStyle from "./footer.module.sass";
import footerCmLogo from "../../images/footer-cm-logo.svg";
import footerIbisLogo from "../../images/footer-ibis-logo.svg";
import footerKrystufekLogo from "../../images/footer-krystufek-logo.svg";
import kapitolLogoSmall from "../../images/kapitol-logo-small.svg";
import phoneLogo from "../../images/icons/phone.svg";
import emailLogo from "../../images/icons/email.svg";
import facebookIcon from "../../images/icons/facebook.svg";
import { Link } from "gatsby";
import { URLS } from "../../constants";

export const SiteDivisions = () => (
    <ul>
        <li>
            <a
                target="_blank"
                rel="noopener noreferrer"
                href={"https://www.kapitol.cz"}
            >
                Finanční poradenství
            </a>
        </li>
        <li>
            <a
                target="_blank"
                rel="noopener noreferrer"
                href={"https://www.kapitolreality.cz"}
            >
                Reality
            </a>
        </li>
        <li>
            <Link aria-current="page" to={"/"}>
                Cenné kovy
            </Link>
        </li>
        <li>
            <a
                target="_blank"
                rel="noopener noreferrer"
                href={"https://www.kapitolautopojisteni.cz"}
            >
                Autopojištění
            </a>
        </li>
    </ul>
);

const Footer = () => (
    <>
        <footer className={footerStyle.footer}>
            <div className={footerStyle.footer__inner}>
                <div className={footerStyle.footer__contact}>
                    <h4>Kontaktujte nás</h4>
                    <p>
                        <img src={phoneLogo} alt="Telefon" />
                        <span>
                            <a href="tel:+420 910 902 737">+420 910 902 737</a>
                            <small>Po–Pá 8:00 - 16:00</small>
                        </span>
                    </p>
                    <p>
                        <img src={emailLogo} alt="E-mail" />
                        <span>
                            <a href="mailto:info@kapitolgold.cz">
                                info@kapitolgold.cz
                            </a>
                        </span>
                    </p>
                    <address>
                        KAPITOL, a.s.
                        <br />
                        Vlněna&nbsp;526/3, Trnitá, 602&nbsp;00&nbsp;Brno
                        <br />
                        IČ: 60751070, DIČ: CZ699000955
                    </address>
                </div>

                <div className={footerStyle.footer__links}>
                    <h4>Důležité odkazy</h4>
                    <Link to={URLS.gdpr}>
                        GDPR - zásady zpracování osobních údajů
                    </Link>
                    <Link to={URLS.termsAndConditions}>
                        Všeobecné obchodní podmínky
                    </Link>
                    <Link to={URLS.whistleblowing}>Whistleblowing</Link>
                    <Link to={URLS.cookies}>O Cookies</Link>
                    <a href="#" onClick={() => Cookiebot.renew()}>
                        Nastavení cookies
                    </a>
                    <Link to={URLS.ethicalCode}>Etický kodex</Link>

                    <div className={footerStyle.footer__copyrightDesktop}>
                        <p>© 2020 Kapitol, Všechna příslušná práva vyhrazena</p>
                    </div>
                </div>

                <div className={footerStyle.footer__logos}>
                    <h4>Strategický partner</h4>
                    <p className={footerStyle.footer__partners}>
                        <img src={footerIbisLogo} alt="IBISINGOLD" />
                    </p>
                    <h4>Partner</h4>
                    <p className={footerStyle.footer__partners}>
                        <img src={footerCmLogo} alt="Česká Mincovna" />
                    </p>
                </div>

                <div className={footerStyle.footer__social}>
                    <h4>Buďme ve spojení</h4>
                    <a href={URLS.fb}>
                        <img src={facebookIcon} alt="Facebook" />
                        Facebook
                    </a>
                    <p className={footerStyle.footer__partners}>
                        <img src={footerKrystufekLogo} alt="Česká Mincovna" />
                    </p>
                </div>
            </div>
            <div className={footerStyle.footer__copyrightMobile}>
                <p>© 2020 Kapitol, Všechna příslušná práva vyhrazena</p>
            </div>
        </footer>

        <nav className={footerStyle.footer__nav}>
            <div className={footerStyle.footer__inner}>
                <img src={kapitolLogoSmall} alt="KAPITOL, a.s." />
                <SiteDivisions />
            </div>
        </nav>
    </>
);

export default Footer;
