import { graphql, Link, StaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React, { useState } from "react";
import classNames from "classnames/bind";

import SiteMenu from "../SiteMenu";
import kapitolLogo from "../../images/kapitol-logo.svg";
import kapitolLogoDark from "../../images/kapitol-logo-dark.svg";
import openMenu from "../../images/icons/menu-open.svg";

import style from "./header.module.sass";
import mobileMenu from "./mobileMenu.module.sass";
import CartBox from "../Cart/CartBox";
import { SiteDivisions } from "../SiteFooter";

let cx = classNames.bind(mobileMenu);

const Index = ({ location, coin, checkout, eshop }) => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    let mobileMenuClass = cx({
        mobileMenu: true,
        mobileMenuOpen: mobileMenuOpen,
    });

    return (
        <StaticQuery
            query={graphql`
                query SiteTitleQuery {
                    site {
                        siteMetadata {
                            title
                        }
                    }
                }
            `}
            render={data => (
                <React.Fragment>
                    <div className={style.headerDivisions}>
                        <div className={style.headerDivisions__inner}>
                            <SiteDivisions />
                        </div>
                    </div>
                    <header
                        className={classNames({
                            [style.header]: true,
                            [style.headerCoin]: coin,
                        })}
                    >
                        <div className={style.header__inner}>
                            {eshop && <CartBox />}
                            <nav className={checkout && style.checkoutHeader}>
                                {checkout && (
                                    <Link to="/e-shop/">
                                        <svg
                                            width="9"
                                            height="25"
                                            viewBox="0 0 9 25"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M8 24L1.35435 12.5L8 1"
                                                stroke="#AD9752"
                                                strokeWidth="2"
                                                strokeMiterlimit="10"
                                            />
                                        </svg>
                                        Zpět&nbsp;<span>do e-shopu</span>
                                    </Link>
                                )}

                                {!checkout && (
                                    <button
                                        className={classNames({
                                            [style.mobileToggle]: true,
                                            [style.mobileToggleDark]: coin,
                                        })}
                                        onClick={() => setMobileMenuOpen(true)}
                                    >
                                        <img
                                            src={openMenu}
                                            alt="Otevřít menu"
                                        />
                                        <span>MENU</span>
                                    </button>
                                )}

                                <Link to="/" className={style.header__logo}>
                                    <img
                                        src={
                                            coin ? kapitolLogoDark : kapitolLogo
                                        }
                                        alt="Kapitol - investice do zlata"
                                    />
                                </Link>
                                {!checkout && (
                                    <SiteMenu
                                        location={location}
                                        type={"desktop"}
                                        coin={coin}
                                    />
                                )}
                            </nav>
                        </div>
                    </header>

                    {!checkout && (
                        <div className={mobileMenuClass}>
                            <div className={mobileMenu.scrollWrapper}>
                                <SiteMenu
                                    location={location}
                                    type={"mobile"}
                                    setMobileMenuOpen={setMobileMenuOpen}
                                />
                            </div>
                        </div>
                    )}
                </React.Fragment>
            )}
        />
    );
};

Index.propTypes = {
    siteTitle: PropTypes.string,
};

Index.defaultProps = {
    siteTitle: "",
};

export default Index;
