import moment from "moment";
import { TYPE_GOLD, TYPE_SILVER, TYPE_PLATINUM } from "../constants";

export const isSubdomain = url =>
    !!url.match(new RegExp(/^([a-z]+:\/{2})?([\w-]+\.[\w-]+\.\w+)$/));

export const createLocalLink = url => {
    if ("#" === url) {
        return null;
    }
    return url.replace(/^https?:\/\/[^/]+/, "");
};

export const formatDateFromTStoIso = timestamp =>
    moment(timestamp).toISOString();

export const isMetalPage = slug =>
    slug === "investice-do-platiny" ||
    slug === "investice-do-stribra" ||
    slug === "zlato";

export const getMetalPageType = slug => {
    if (slug === "investice-do-stribra") {
        return TYPE_SILVER;
    } else if (slug === "investice-do-platiny") {
        return TYPE_PLATINUM;
    } else if (slug === "zlato") {
        return TYPE_GOLD;
    }

    return null;
};

export const getTypeByTags = tags => {
    if (tags && tags.length === 1) {
        if (tags[0].name === "zlato") {
            return TYPE_GOLD;
        }
        if (tags[0].name === "stribro") {
            return TYPE_SILVER;
        }
        if (tags[0].name === "platina") {
            return TYPE_PLATINUM;
        }
    }

    return TYPE_GOLD;
};

export const isSilverCoinTheme = theme => theme === "silver";
