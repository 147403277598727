import React, { Component } from "react";
import classNames from "classnames/bind";
import PropTypes from "prop-types";
import styles from "./block.module.sass";

let cx = classNames.bind(styles);

class Block extends Component {
    render = () => {
        const {
            leftIndent,
            radialGradient,
            radialGradientPamp,
            radialGradientCoin,
            background,
            children,
            coin,
            coinSilver,
            grey,
            darkBlue,
            fullWidth,
            cart,
        } = this.props;

        let block = cx({
            block: true,
            blockRadialGradient: radialGradient,
            blockRadialGradientPamp: radialGradientPamp,
            blockRadialGradientCoin: radialGradientCoin,
            background: background,
            coin: coin,
            coinSilver: coinSilver,
            grey: grey,
            darkBlue: darkBlue,
        });

        let inner = cx({
            inner: true,
            innerLeftIndent: leftIndent,
            innerFullWidth: fullWidth,
            cart: cart,
        });

        return (
            <div className={block}>
                <div className={inner}>{children}</div>
            </div>
        );
    };
}

Block.propTypes = {
    cart: PropTypes.bool,
    leftIndent: PropTypes.bool,
    radialGradient: PropTypes.bool,
    radialGradientPamp: PropTypes.bool,
    radialGradientCoin: PropTypes.bool,
    background: PropTypes.bool,
    grey: PropTypes.bool,
    fullWidth: PropTypes.bool,
};

Block.defaultProps = {
    cart: false,
    leftIndent: false,
    radialGradient: false,
    radialGradientPamp: false,
    radialGradientCoin: false,
    background: false,
    fullWidth: false,
};

export default Block;
