import React from "react";
import { graphql, Link, StaticQuery } from "gatsby";
import RenderSubMenu from "./RenderSubMenu";
import { createLocalLink, isSubdomain } from "../../utils";
import style from "./sitemenu.module.sass";
import kapitolLogoDark from "../../images/kapitol-logo-dark.svg";
import mobileMenu from "../SiteHeader/mobileMenu.module.sass";
import closeMenu from "../../images/icons/menu-close.svg";
import classNames from "classnames/bind";
import { SiteDivisions } from "../SiteFooter";

const MENU_QUERY = graphql`
    fragment MenuFields on WPGraphQL_MenuItem {
        id
        label
        url
        connectedObject {
            __typename
        }
    }

    query GET_MENU_ITEMS {
        wpgraphql {
            menuItems(where: { location: MENU_1 }) {
                nodes {
                    ...MenuFields
                    childItems {
                        nodes {
                            ...MenuFields
                            childItems {
                                nodes {
                                    ...MenuFields
                                    childItems {
                                        nodes {
                                            ...MenuFields
                                            childItems {
                                                nodes {
                                                    ...MenuFields
                                                    childItems {
                                                        nodes {
                                                            ...MenuFields
                                                            childItems {
                                                                nodes {
                                                                    ...MenuFields
                                                                    childItems {
                                                                        nodes {
                                                                            ...MenuFields
                                                                            childItems {
                                                                                nodes {
                                                                                    ...MenuFields
                                                                                    childItems {
                                                                                        nodes {
                                                                                            ...MenuFields
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const MenuLink = ({ menuItem }) => {
    if (isSubdomain(menuItem.url)) {
        return (
            <a href={menuItem.url} key={menuItem.id}>
                <span>{menuItem.label}</span>
            </a>
        );
    }
    if (createLocalLink(menuItem.url)) {
        return (
            <Link key={menuItem.id} to={createLocalLink(menuItem.url)}>
                <span>{menuItem.label}</span>
            </Link>
        );
    }
    return menuItem.label;
};

export const renderMenuItem = (location, menuItem, iteration, type) => {
    const link = createLocalLink(menuItem.url);

    if (menuItem.childItems && menuItem.childItems.nodes.length) {
        return (
            <RenderSubMenu
                menuItem={menuItem}
                type={type}
                key={menuItem.url}
                location={location}
                iteration={iteration}
            />
        );
    } else {
        return (
            <li
                key={menuItem.id}
                className={classNames({
                    [style.activeTopLevel]:
                        menuItem.childItems.nodes.findIndex(
                            item =>
                                item.url === location.pathname ||
                                location.pathname === link
                        ) > -1,
                    [style.noBackground]: true,
                })}
            >
                <MenuLink menuItem={menuItem} />
            </li>
        );
    }
};

const SiteMenu = ({ location, type, coin, setMobileMenuOpen }) => (
    <StaticQuery
        query={MENU_QUERY}
        render={data => {
            if (data.wpgraphql.menuItems) {
                return (
                    <div
                        className={
                            type === "mobile"
                                ? style.mobileMenu
                                : style.desktopMenu
                        }
                    >
                        <div className={style.headerContainer}>
                            <Link to="/" className={style.header__logo}>
                                <img
                                    src={kapitolLogoDark}
                                    alt="Kapitol - investice do zlata"
                                />
                            </Link>

                            <button
                                className={mobileMenu.mobileMenuClose}
                                onClick={() => setMobileMenuOpen(false)}
                            >
                                <img src={closeMenu} alt="Zavřít menu" />
                                <span>Zavřít</span>
                            </button>
                        </div>

                        <div className={mobileMenu.divisionMenu}>
                            <SiteDivisions />
                        </div>

                        <ul className={coin && style.coinMenu}>
                            {data.wpgraphql.menuItems.nodes.map(menuItem =>
                                renderMenuItem(location, menuItem, 1, type)
                            )}
                        </ul>
                    </div>
                );
            } else {
                return null;
            }
        }}
    />
);

export default SiteMenu;
