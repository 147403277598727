import React from "react";
import { Link } from "gatsby";
import classNames from "classnames/bind";

import style from "./sitemenu.module.sass";

import { createLocalLink } from "../../utils";
import { renderMenuItem } from "./index";

class RenderSubMenu extends React.Component {
    state = {
        menuOpen: false,
    };

    handleClickOutside = evt => {
        this.setState({ menuOpen: false });
    };

    openSubmenu = () => {
        this.setState({ menuOpen: !this.state.menuOpen });
    };

    render = () => {
        const { menuItem, type, location, iteration } = this.props;

        return (
            <li
                className={
                    this.state.menuOpen
                        ? type === "mobile"
                            ? style.mobileSubmenuMenu
                            : style.desktopSubmenuMenu
                        : undefined
                }
            >
                {iteration > 1 && (
                    <Link
                        className={classNames({
                            [style.categoryLink]: true,
                            [style.activeTopLevel]:
                                menuItem.childItems.nodes.findIndex(
                                    item =>
                                        item.url === location.pathname ||
                                        location.pathname ===
                                            createLocalLink(menuItem.url)
                                ) > -1,
                        })}
                        to={createLocalLink(menuItem.url)}
                    >
                        <span>{menuItem.label}</span>
                    </Link>
                )}
                {iteration <= 1 &&
                    (type === "desktop" ? (
                        <Link
                            className={
                                menuItem.childItems.nodes.findIndex(
                                    item =>
                                        item.url === location.pathname ||
                                        location.pathname ===
                                            createLocalLink(menuItem.url)
                                ) > -1
                                    ? style.activeTopLevel
                                    : null
                            }
                            to={createLocalLink(menuItem.url)}
                        >
                            <span>{menuItem.label}</span>
                        </Link>
                    ) : (
                        <button
                            onClick={this.openSubmenu}
                            className={
                                menuItem.childItems.nodes.findIndex(
                                    item =>
                                        item.url === location.pathname ||
                                        location.pathname ===
                                            createLocalLink(menuItem.url)
                                ) > -1
                                    ? style.activeTopLevel
                                    : null
                            }
                        >
                            <span>{menuItem.label}</span>
                        </button>
                    ))}
                <ul
                    className={
                        menuItem.childItems.nodes[0].childItems.nodes.length !==
                        0
                            ? style.deep
                            : null
                    }
                >
                    {this.state.menuOpen && (
                        <li>
                            <Link
                                className={style.categoryLink}
                                to={createLocalLink(menuItem.url)}
                            >
                                <span>Úvod</span>
                            </Link>
                        </li>
                    )}
                    {menuItem.childItems.nodes.map(item =>
                        renderMenuItem(location, item, iteration + 1)
                    )}
                </ul>
            </li>
        );
    };
}

export default RenderSubMenu;
