import { DELIVERY_PRICE, FREE_DELIVERY_LIMIT } from "../../constants";

export const cartSum = (cart, hasFreeDelivery) => {
    const productTotal = Object.keys(cart).reduce(function(sum, key) {
        return (
            sum +
            parseInt(cart[key].itemPrice, 10) * parseInt(cart[key].quantity, 10)
        );
    }, 0);

    let total = productTotal;
    let delivery = "zdarma";

    if (productTotal < FREE_DELIVERY_LIMIT && !hasFreeDelivery) {
        total = productTotal + DELIVERY_PRICE;
        delivery = DELIVERY_PRICE;
    }

    return {
        totalPrice: total,
        productTotal: productTotal,
        delivery: delivery,
    };
};
