export const TYPE_GOLD = "gold";
export const TYPE_SILVER = "silver";
export const TYPE_PLATINUM = "platinum";

export const URLS = {
    linked:
        "https://cz.linkedin.com/company/kapitol-pojistovaci-a-financni-poradenstvi",
    fb: "https://cs-cz.facebook.com/kapitolczech/",
    instagram: "https://www.instagram.com/kapitol.cz/",
    yt: "http://youtube.com/channel/UCPq0pbyxbIC6HfQ4HLAMkuQ",
    gdpr: "/informacni-memorandum-o-zpracovani-osobnich-udaju/",
    cookies: "/cookies",
    whistleblowing: "/whistleblowing",
    complaintsProcedure: "/wp-content/uploads/2020/11/Reklamacni-rad.pdf",
    ethicalCode: "/wp-content/uploads/2020/11/Eticky_kodex-KAPITOL.pdf",
    termsAndConditions: "/obchodni-podminky",
    termsAndConditionsCm:
        "https://ceskamincovna.cz/vseobecne-obchodni-podminky-2270/",
    gdprCm: "https://ceskamincovna.cz/podminky-zpracovani-osobnich-udaju-1809/",
};

export const SORT_TYPES = [
    {
        label: "nejdražšího",
        slug: "priceDesc",
    },
    {
        label: "nejlevnějšího",
        slug: "priceAsc",
    },
];

export const DEFAULT_EMITENT = {
    label: "Vše",
    slug: "vse",
};

export const DEFAULT_STOCK = true;

export const IN_STOCK = "Skladem";

export const OUT_OF_STOCK = "Nyní nedostupné";

export const PRODUCTS_PER_PAGE = 8;

export const FREE_DELIVERY_LIMIT = 2500;
export const DELIVERY_PRICE = 111;

export const GOLD_PRICE_KELT = 54990;
export const SILVER_PRICE_KELT = 1690;

export const DEFAULT_DELIVERY_PRICE = 90;

export const GOLD_MAX_AMOUNT_KELT = 50;
export const SILVER_MAX_AMOUNT_KELT = 500;

export const KAPITOL_TERMS_AND_CONDITIONS_URL = "/obchodni-podminky";
export const KAPITOL_GDPR_URL =
    "/informacni-memorandum-o-zpracovani-osobnich-udaju";
export const CM_TERMS_AND_CONDITIONS_URL =
    "https://ceskamincovna.cz/vseobecne-obchodni-podminky-2270/";
export const CM_GDPR_URL =
    "https://ceskamincovna.cz/podminky-zpracovani-osobnich-udaju-1809/";

export const CM_MAX_ITEM_ORDER_QUANTITY = 5;

let eighteenYearsAgo = new Date();

export const EIGHTEEN_YEARS_AGO = new Date(
    eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18)
);
